#container-login, #container-reset {
  display: flex;
  flex-direction: column;
  // top: calc(#{$navbar-height} + #{$navbar-border-width});
  // height: calc(100% - #{$navbar-height} - #{$navbar-border-width});
  // width: 100%;
  // position: fixed;
  left: 0;

  & > form, & > div {
    max-width: 500px;
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    background-color: $white;
    box-shadow: $box-shadow;
  }

  input {
    @include pl-placeholder { text-align: center; }
  }

  .panel-warning {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .first-time-login {
    margin-top: 15px;
    text-align: center;

    a {
      display: inline-block;
      padding: 5px 10px;
      border-right: 1px solid $gray-lighter;

      &:last-of-type {
        border-right: 0;
      }
    }
  }
}
