
.list-question-type-check {
  padding-left: 0px;
  li {
    list-style-type: none;
    label {
      position:relative;
      width: 100%;
      margin-bottom: 10px;
    }
    input[type="checkbox"] {
      position: absolute;
      top: -20px;
      display: none;
      left: -4000px;
      & + label span {
        color: #333;
        display: block;
        padding: 10px 20px;
        background-color: #eee;
        border-radius: 4px;
        text-align: center;
        line-height: 25px;
      }
      & + label span:hover {
        background-color: #d4d4d4;
        color: $gray-darker;
      }
      & + label span:before {
        content: '';
        position: absolute;
        left: 10px;
        width: 25px;
        height: 25px;
        background-color: $white;
        text-align: center;
      }
      &:checked + label span {
        background-color: $teal;
        color: $white;
      }
      &:checked + label span:before {
        content:  '\e013';
        font-family: 'Glyphicons Halflings';
        font-size: 18px;
        line-height: 1.4;
        font-style: normal;
        font-weight: 400;
        background-color: $white;
        color: $teal;
      }
      &:checked:hover + label span {
        background-color: $teal;
        color: $white;
      }
    }
  }
}
