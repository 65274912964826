#survey-detail-panel {

  &> .panel-heading {
    position: relative;
    .survey-name {
      font-size: 2em;
    }
    button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }

  .editable-content {
    i {
      display: inline-block;
      opacity: 0;
      padding: 5px 2px;
      font-size: 15px;
      color: $slate;
      cursor: pointer;
      transition: all .3s ease;
      &.edit:hover {
        color: $teal;
      }
      &.delete:hover {
        color: $brand-danger;
      }
    }
    &:hover i {
      opacity: 1;
    }
  }

  /* Status Bar ---------------------------*/

  .status-bar {
    position: relative;
    padding: $panel-heading-padding;
    background-color: $grey;
    margin-bottom: 15px;
    border-radius: 4px;
  }

  /* Question Group ---------------------------*/

  .questionSetHeader {
    position: relative;
    padding: 5px 0 15px;
    font-weight: bold;
    text-align: center;
    font-size: 1.3em;
    .actions, .dragger {
      position: absolute;
    }
    .dragger {
      top: 8px;
      left: 5px;
      font-size: 14px;
      color: $slate;
    }
    .actions {
      top: 0;
      right: 5px;
      button {
        padding: 8px 15px;
        border: 0;
      }
    }
  }

  /* Questions -----------------------------*/

  .question-list {
    .empty-state {
      display: block;
      height: 51px;
      padding: 12px 0;
      text-align: center;
      border: dashed 2px $slate;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .question {
    position: relative;
    background: $white;
    margin-bottom: 5px;
    border-radius: 0;
    &:last-of-type {
      margin-bottom: 0;
    }
    .dragger {
      float: left;
      padding: 4px 15px 4px 0;
      font-size: 14px;
      color: $border-grey;
    }
    .question-header {
      padding: 10px;
      padding-right: 135px;
      .question-title {
        font-size: 16px;
        line-height: 1.5;
      }
      .question-text {
        width: calc(100% - 150px);
        margin-right: 5px;
      }
      .question-type {
        color: $border-grey;
        font-size: .9em;
        font-style: italic;
        text-transform: capitalize;
      }
      .required {
        .glyphicon-star {
          color: $brand-warning;
        }
        .glyphicon-star-empty {
          color: $border-grey;
        }
      }
      .question-actions {
        position: absolute;
        top: 0;
        right: 0;
        height: 45px;
        font-size: 17px;
        .action-icons {
          i {
            opacity: 0;
            padding: 5px;
            color: $slate;
            transition: opacity .2s ease;
            &.location:hover {
              color: $dark-teal;
            }
            &.edit:hover {
              color: $teal;
            }
            &.delete:hover {
              color: $brand-danger;
            }
          }
        }
        .show-details {
          display: inline-block;
          padding: 10px;
          cursor: pointer;
        }
      }
    }
    &:hover {
      .question-header .question-actions i {
        opacity: 1;
      }
    }
    .question-detail {
      padding: 20px;
      border-top: 1px solid $light-grey;
    }
    .qa-divider {
      .col-md-8.form-group {
        margin-bottom: 15px;
      }
      .locations {
        span {
          margin-bottom: 5px;
        }
      }
    }
  }

  /* Answers -----------------------------*/

  .answer-list {
    .answer {
      margin: 0 0 5px 0;
      .answer-header {
        position: relative;
        padding: 7px;
        background: $light-grey;
        border-left: 3px solid $teal;
        .answer-text {
          line-height: 2;
          display: inline-block;
          margin: 0 5px;
          padding-right: 150px;
        }
        .answer-actions {
          position: absolute;
          top: 6px;
          right: 10px;
          font-weight: bold;
          font-size: 85%;
          .show-details {
            padding: 5px;
          };
          .show-followup-instructions {
            position: relative;;
            top: 7px;
            right: 5px;
            i {
              font-size: 20px;
              cursor: pointer;
            }
          }
        }
      }
      .answer-detail {
        background: $border-grey;
        border-left: 3px solid $teal;
      }
      .question-level-detail {
        background: $border-grey;
        border-left: 3px solid $teal;
      }
    }
  }
  .add-answer {
    margin-top: 20px;
  }
  .followup-questions {
    padding-left: 20px;
    .question {
      margin-top: 5px;
      border-left: 3px solid $border-grey;
      .question-actions {
        right: 10px;
        .action-icons i {
          opacity: 0;
          margin: 0;
          padding: 12px 5px;
          font-size: 15px;
        }
      }
      &:hover .question-actions .action-icons i {
        opacity: 1;
      }
    }
    .followup-answers {
      padding: 0 0 0 30px;
      .followup-answer {
        &:last-of-type {
          margin-bottom: 0;
        }
        .followup-answer-text {
          margin-right: 5px;
        }
      }
    }
  }

  .survey-list, .panel-body > div:nth-child(3) {
    position: relative;
    min-height: 64px;
    display: block;
    background-color: $border-grey;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 4px;
  }

  .dndDragging {
    opacity: 0.7;
  }
  .dndDraggingSource {
    display: none;
  }
  .dndPlaceholder {
    background: #c4ced2;
    min-height: 64px;
    display: block;
    position: relative;
  }


  .question-level-detail {
    margin: -15px;
    padding: 10px;
    background-color: $border-grey;
  }

  .question-levels {
    span {
      margin-right: 5px;
      font-size: 0.9em;
    }
    ul {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 5px;
    }
    li {
      display: inline-block;
      padding: 2px 7px;
      background-color: $white;
      border-radius: 5px;
      margin: 2px 2px 2px 0;
      border: 1px solid $border-grey;
      border-radius: 20px;
      font-size: .85em;
      line-height: 1.5;
    }
  }

  .answer-detail {
    padding: 10px;
  }

  .answer-tags, .answer-levels {
    span {
      margin-right: 5px;
      font-size: 0.9em;
    }
    ul {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 5px;
    }
    li {
      display: inline-block;
      padding: 2px 7px;
      background-color: $white;
      border-radius: 5px;
      margin: 2px 2px 2px 0;
      border: 1px solid $border-grey;
      border-radius: 20px;
      font-size: .85em;
      line-height: 1.5;
    }
  }

  .save-panel {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: $white;
    box-shadow: 0 -1px 2px rgba(0,0,0,0.2);
    text-align: center;

    button {
      margin: 10px 0;
      font-weight: bold;
    }
  }
}


/*-----------------------------------------
  MEDIA QUERIES
------------------------------------------*/
@media (max-width: 768px) {
  #survey-detail-panel {
    padding: 0;
    .panel-body {
      .action-icons {
        i {
          &.glyphicon-pencil {
            opacity: 1;
          }
          &.glyphicon-trash {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media (max-width: 568px) {
  #survey-detail-panel {
    .panel-available-to {
      h4 {
        float: none !important;
        margin: 0;
        text-align: center;
      }
      .locations {
        text-align: center;
        span {
          margin-top: 10px;
        }
        button {
          margin-top: 10px;
        }
      }
    }
    .panel-body {
      padding: 10px;
      .dragger {
        padding-right: 10px;
      }
      .question-title {
        font-size: 16px;
        width: calc(100% - 150px);
      }
      .action-icons {
        i {
          font-size: 18px;
        }
      }
    }
  }
}
