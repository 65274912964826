#surveyForm {
  padding: 30px;
  margin-bottom: 50px;

  // h3
  .question-set-title { margin-bottom: 25px; }


  .embed {
    max-width: 400px;
    width: 100%;
    height: 275px;
  }

  .btn-review, .btn-start {
    margin-top: 15px;
    width: 100%;
    padding: 20px;
  }

  .btn-previous {
    &[disabled] { background-color: $grey; }
  }
  .btn-next {}
}
