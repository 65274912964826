.users-panel-nav {
  margin: -20px -15px 0;
  background-color: $dark-teal;
  .navbar-nav > li > a {
    color: $white !important;
  }
  .nav > li > a:focus,
  .nav > li > a:hover {
    background: darken($dark-teal, 5%);
  }
}
.form-inline {
  .form-group {
    width: 48%!important;
  }
  .form-control {
    width: 100%;
  }
  .inline-second {
    margin-left: 3% !important;
  }
}
#users-panel {
  .panel-heading {
    margin-bottom: 15px;
    color: $white;
    h1 {
      position: relative;
      &.delete {
        color: $brand-danger;
        transition: 0.5s;
      }
    }
    .btn-container {
      margin: 22px 0 0;
      .glyphicon {
        padding-right: 10px;
      }
    }
  }
  .group-header {
    &:hover {
      .delete-group, .edit-group {
        transition: 0.5s;
      }
      .delete-group { color: $brand-danger; }
      .edit-group { color: $brand-success; }
    }
    .delete-group, .edit-group {
      color: transparent;
      position: absolute;
      font-size: 0.55em;
      font-weight: bold;
      padding: 0;
      margin: 0;
      top: 14px;
    }
    .delete-group {
      right: -60px;
    }
    .edit-group {
      right: -30px;
    }
  }

  .panel-body {
    background-color: $white;
    border: 1px solid $border-grey;
    margin-bottom: 5px;
    position: relative;
    .survey-title {
      display: inline-block;
      font-size: 1.8rem;
      padding-right: 40px;
    }
    .action-icons {
      margin-top: 5px;
      .toggle-wrap {
        opacity: 0;
        position: relative;
        display: inline-block;
        .toggle {
          position: relative;
          top: -8px;
          right: 5px;
        }
      }
      i {
        cursor: pointer;
        font-size: 17px;
        &.glyphicon-pencil {
          color: $teal;
          margin-right: 10px;
          opacity: 0;
          transition: all 0.3s ease;
        }
        &.glyphicon-trash {
          //color: $teal;
          margin-right: 10px;
          opacity: 0;
          transition: all 0.3s ease;
        }
      }
    }
    &:hover {
      .toggle-wrap {
        opacity: 1;
      }
      .action-icons {
        i {
          &.glyphicon-pencil {
            opacity: 1;
          }
          &.glyphicon-trash {
            opacity: 1;
          }
        }
      }
    }
    .locations {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid $light-grey;
      label {
        margin-right: 5px;
        font-weight: normal;
      }
      span {
        background: $light-grey;
        border: 1px solid $border-grey;
        padding: 9px 15px;
        margin-right: 5px;
        display: inline-block;
        margin-bottom: 7px;

        background: $light-grey;
        border: 1px solid $border-grey;
        border-radius: 20px;
        padding: 7px 10px;
        margin-right: 3px;
        display: inline-block;
        font-size: 0.85em;
      }
      button {
        vertical-align: baseline;
        padding: 7px 10px;
      }
    }
  }
  .users-list {
    margin-bottom: 5px;
  }
}
.btn-store,
.btn-store:active .btn-store:focus {
  background-color: $light-grey;
  border: 1px solid $border-grey;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 16px;
  border-radius: 0;
}

.locations-list, .permissions-list, .levels-list {
  button {
    margin-bottom: 10px;
  }
  input[type=checkbox]:checked + label {
    background: $teal;
    color: $white;
  }
  input[type=checkbox] {
    float: left;
    opacity: 0;
  }
  .btn-store {
    span {
      display: block;
      margin-left: 0;
    }
    &:hover {
      background: darken($light-grey, 5%);
      color: $grey;
    }
  }
}
#edit-user {
  a {
    color: $teal;
  }
  .password-reset {
    margin-top: 10px;
    background: $light-grey;
    border: 1px solid $border-grey;
    padding: 30px;
  }
}

#import-user-modal {
  .dragover {
    background-color: $brand-success;
  }
  .dragover-bad {
    background-color: $brand-danger;
  }
}

#add-user {
  position: relative;
  .delete-unapproved-user {
    display: block;
    padding: 5px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 100;
    font-size: 18px;
  }

  .btn-prev, .btn-next {
    position: absolute;
    display: inline-block;
    background: #fff;
    border-radius: 0;
    color: $teal;

    &:hover {
      color: $dark-teal;
    }
  }
  .btn-prev {
    left: 0;
    border-right: 1px solid $gray-lighter;
  }
  .btn-next {
    right: 0;
    border-left: 1px solid $gray-lighter;
  }
}


.duplicate-group {
  padding: 15px 0;
  border-bottom: 1px solid $gray-lighter;

  .row {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .user-name {
    font-weight: bold;
  }
  .user-detail {
    font-weight: 0.9em;
  }
}

#users-panel, .import_users {

  .form-group {
    margin: 0;
    padding: 20px;
    border-bottom: 1px solid $gray-lighter;
  }

  .vertical-center-parent {
    display: table;
    float: left;
    height: 40px;
  }
  .vertical-center-child {
    display: table-cell;
    vertical-align: middle;
  }
  .no-border {
    border: 0;
  }
  #approve-user-container {
    border-bottom: 1px solid #d3dadd;
    padding: 0 0 20px;
  }
  #unapproved-users-alert {
    display: inline-block;
    width: 100%;
    margin: 20px 0 10px;
    padding: 10px 15px;
  }
  .center-horizontally {
    margin: 0 auto;
    text-align: center;
  }
  .left-margin {
    margin-left: 20px;
  }
  .left-align-text {
    margin: 0 auto;
    text-align: left;
  }
  .inline-block {
    display: inline-block;
  }
  .block {
    display: block;
  }
  .top-padding {
    padding-top: 20px;
  }
  .limit-height-scrollable {
    height: calc(100vh - 350px);
    overflow-y: auto;
  }
  .limit-height-scrollable-large {
    max-height: 500px;
    overflow-y: scroll;
  }
  .control-label input[type="checkbox"] {
    margin-right: 5px;
  }
  .percentage-width {
    width: 90%;
    margin: auto;
  }
  .border-top {
    border-top: 1px solid $border-grey;
  }
  .border-bottom {
    border-bottom: 1px solid $border-grey;
  }
  .title-text {
    padding: 20px;
    margin: 0;
  }
  .third-percentage-width {
    width: 33%;
  }
  .half-percentage-width {
    width: 50%;
  }
  .overflow-auto {
    overflow: auto;
  }
  .no-margin-bottom {
    margin-bottom: 0;
  }
  .detail-text {
    color: $gray;
    font-size: 11px;
    line-height: 1.2;
  }
  .no-margin {
    margin: 0;
  }
  .no-padding {
    padding: 0;
  }
  .margin-top {
    margin-top: 10px;
  }
  .margin-auto {
    margin: auto;
  }
  .margin-bottom {
    margin-bottom: 15px;
  }
  .padding-top-bottom {
    padding: 20px 0;
  }
  .regular-font-weight {
    font-weight: normal;
  }
  .bold-font-weight {
    font-weight: bold;
  }
  .anti-pull {
    margin-top: 30px;
  }
  .center-anti-pull {
    margin-top: 45px;
  }
  .minus-margin-bottom {
    margin-bottom: -10px;
  }
  .special-text-color {
    color: #7a7a7a;
  }
  .small-text {
    font-size: 12px;
  }
  #connection-instructions {
    margin: 0;
    padding: 10px 0;
  }
  #fileName {
    display: inline-block;
    margin-left: 20px;
  }
  #formatting-instructions {
    margin-top: 20px;
  }
  .hide {
    display: none;
  }
  .nearly-half-width {
    width: 49%;
  }
}
/*-----------------------------------------
  MEDIA QUERIES
------------------------------------------*/
@media (max-width: 940px) {
  #users-panel {
    .panel-heading {
      h1 {
        float: none !important;
      }
      .btn-container {
        float: none !important;
        .btn-primary {
          width: 31%;
        }
        .btn-default {
          width: 36.5%;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  #users-panel {
    padding: 0;
    .panel-body {
      .action-icons {
        i {
          &.glyphicon-pencil {
            opacity: 1;
          }
          &.glyphicon-trash {
            opacity: 1;
          }
        }
      }
    }
  }
}


@media (max-width: 568px) {
  #users-panel {
    .panel-heading {
      margin-bottom: 0;
      .btn-container {
        margin-top: 10px;
        .btn {
          display: block;
          margin-bottom: 5px;
          width: 200px;
        }
      }
    }
    .panel-body {
      .survey-title {
        font-size: 16px;
        width: calc(100% - 80px);
        padding-right: 0;
      }
      .action-icons {
        i {
          font-size: 18px;
        }
      }
      .locations {
        span {
          padding: 5px 10px;
        }
        button {
          padding: 7px 10px;
        }
      }
    }
  }
}


#add-permissions {
  .modal-body {
    max-height: 65vh;
    height: 100%;
    overflow-y: scroll;
  }
}
