
.list-question-type-radio {
  padding-left: 0px;
  li {
    list-style-type: none;
    label {
      position:relative;
      width: 100%;
      margin-bottom: 10px;
    }
    input[type="radio"] {
      top: -20px;
      left: -4000px;
      display: none;
      & + span {
        color: #333;
        display: block;
        padding: 10px 20px;
        background-color: #eee;
        border-radius: 4px;
        text-align: center;
      }
      & + span:hover {
        background-color: #d4d4d4;
        color: $gray-darker;
      }
      &:checked + span {
        background-color:  $teal;
        color: $white;
      }
      &:checked:hover + span {
        background-color:  $teal; //$brand-success;
        color: $white;
      }
    }
  }
}
