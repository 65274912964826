@keyframes loadingani {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

[ui-view=content].loading {
  position: relative;

  &::before {
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: fade-out($grey, 0.75);
    z-index: 1000;
  }

  &::after {
    position: fixed;
    top: 50%;
    left: 50%;
    content: "\e031";
    font-family: 'Glyphicons Halflings';
    color: $grey;
    z-index: 1055;
    font-size: 10rem;
    animation-name: loadingani;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
