.striker {
  display: flex;
  align-items: center;
  text-align: center;
  color: $gray-light;
  font-style: italic;
  margin-bottom: 20px;
  b {
    color: $gray;
    font-weight: normal;
  }
  b,
  span {
    &:not(:first-child) {
      &::before {
        content: '\00a0';
      }
    }
  }
  &::after,
  &::before {
    content: '';
    flex: 1;
    border-bottom: 1px solid $gray-lighter;
  }
  &::before {
    margin-right: 1.8rem;
  }
  &::after {
    margin-left: 1.8rem;
  }
}
