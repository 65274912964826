.accounts {
  &__info {
    display: flex;
  }

  &__panel {
    margin: -20px -30px 0;
    background-color: #00a2a5;
  }

  &__page {
    padding-top: 60px;
  }

  &__add {
    margin: 22px 0 22px auto;
  }

  &__title {
    font-size: 18px;
    text-align: left;
    display: block;
    white-space: nowrap;
  }

  &__item {
    padding: 15px 0;
    color: #FFF;
  }

  &__details {
    background-color: #fff;
  }

  &__row {
    border: 1px solid #d3dadd;

      td {
        padding: 15px;
      }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__form-body {
    max-height: calc(100vh - 190px);
    overflow: auto;
    padding: 30px 40px;
  }

  &__form-footer {
    padding: 10px;
    border-top: 1px solid #eef3f6;
    text-align: right;
  }

  &__header {
    display: flex;
  }

  &__wrap {
    padding: 3rem;
    padding-bottom: 0;
    background-color: #fff;
  }

  &__line {
    width: 100%;
    display: block;
    border-bottom: 1px solid $border-grey;
    margin-left: 3rem;
    top: -50%;
    height: 14px;
  }

  &__table {
    padding: 15px;
    border: 1px solid #d3dadd;
    width: 100%;
  }

  &__wrapper {
    display: flex;
    padding: 0 15px;
    line-height: 23px;
  }

  &__name {
    padding: 15px;
    color: #fff;
  }

  &__actions {
    margin-left: auto;
    color: #fff;
    &:hover {
      background: #00898c;
    }
    span {
      padding: 15px;
      display: block;
    }
  }
}
