#profile {
  .panel-body {
    border: 1px solid $border-grey;
    background: $white;
    padding-bottom: 30px;
  }
  .address {
    margin-top: 25px;
  }

  .form-group {
    margin: 15px 0 0 5px;
  }

  .form-password {
    margin-top: 0;
  }

  #sms {
    margin: 0 5px 0 15px;
  }

}
