.question-modal > .modal-dialog {
  width: 768px !important;
}

/* Add Question Modal -----------------------*/
#add-question {
  margin-bottom: 0;
  padding: 0;
  select {
    text-align-last: center;
    // Chrome only I'm afraid
  }
  .modal-body {
    min-height: 400px;
    height: 100%;
    overflow: auto;
    padding: 0;
  }
  /* Question Tab Content ---------------------------------------*/
  .question-input {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-grey;
  }
  .question-check {
    margin-bottom: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $border-grey;
    &:last-child {
      border-bottom: none;
    }
    label {
      margin-top: 0;
      margin-bottom: 2px;
      font-weight: bold;
      input {
        margin-left: 0;
        position: static;
        margin-right: 2px;
        vertical-align: top;
      }
    }
    p {
      margin: 0 0 0 18px;
      font-size: 12px;
    }
  }
  .question-type {
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid $border-grey;
    &[data-label]:before {
      content: attr(data-label);
      display: block;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
  .form-group {
    input[type="text"] {
      margin-bottom: 20px;
    }
    > textarea {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
  /* Answers Tab Content ---------------------------------------*/
  .add-question-answer {
    background-color: $light-grey;
    padding: 15px;
    border: 1px solid $border-grey;
    margin-bottom: 10px;
    .answer-title {
      margin-bottom: 15px;
    }
    .answer-body {
      padding-bottom: 15px;
      border-bottom: 1px solid $border-grey;
      input[type="text"] {
        margin-bottom: 20px;
      }
      .checkbox-inline input[type="checkbox"] {
        position: relative;
        margin: 0;
      }
      .checkbox-inline,
      .radio-inline {
        margin-right: 20px;
        padding-left: 0;
      }
      ul {
        li {
          display: inline-block;
          padding-left: 10px;
        }
      }
    }
    .save-answer {
      .button-one,
      .button-two {
        min-width: 145px;
      }
    }
  }
  .urgent-notification {
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $border-grey;

    .question-check {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
      label {
        font-weight: bold;
        margin-bottom: 0;
      }
      i {
        margin-left: 5px;
        vertical-align: middle;
      }
      &.border {
        padding-right: 15px;
        margin-right: 15px;
        border-right: 1px solid $border-grey;
      }
      .info {
        position: relative;
        &:hover {
          .info-hover {
            display: block;
          }
        }
      }
      .info-hover {
        position: absolute;
        width: 250px;
        bottom: 25px;
        left: -125px;
        background: $slate;
        padding: 15px;
        display: none;
        p {
          margin-left: 0;
          color: #fff;
        }
      }
    }
  }
  .answer-levels {
    ul {
      overflow-y: auto;
      max-height: 130px;
      padding: 10px;
      li {
        display: inline-block;
        width: 50%;
        font-size: 13px;
      }
    }
  }
  .question-tags {
    overflow-y: auto;
    max-height: 130px;
    padding: 10px;
    padding-left: 18px;
    margin-bottom: 0;

    li {
      display: inline-block;
      width: 33%;
      font-size: 13px;
    }
  }
  .add-answer-btn {
    background-color: $teal;
    color: white;
  }
  /* Tabs ---------------------------------------*/
  .nav-tabs {
    width: 25%;
    min-height: 400px;
    float: left;
    border-bottom: none;
    > li {
      margin-bottom: 0;
      border-bottom: 1px solid $border-grey;
      background-color: 0;
      transition: background-color 0.25s;
      &.disabled {
        background-color: $gray-light;
        transition: background-color 0.25s;
        a:hover { background-color: $gray-light; }
      }
      a {
        margin-right: 0;
        padding: 15px;
        border: none;
        border-radius: 0;
        color: $grey;
        &:hover {
          transition: background-color 0.25s;
          background: $light-grey;
        }
      }
      &.active a {
        transition: background-color 0.25s;
        background: $teal;
        color: $white;
      }
    }
  }
  .nav-stacked > li+li {
    margin-top: 0;
  }
  .tab-content {
    width: 75%;
    min-height: 50vh;
    max-height: 75vh;
    float: left;
    padding: 30px;
    border-left: 1px solid $border-grey;
    overflow: auto;
  }
  .control-label {
    font-weight: 300;
    font-size: 12px;
    input[type=checkbox] {
      margin-left: 0;
    }
  }
  .modal-footer {}

  .follow-up {
    .followup-group {
      background-color: $light-grey;
      margin-bottom: 15px;
      padding: 15px;
      border: 1px solid $border-grey;
    }
    .answer-text {
      text-align: center;
    }
    .answer-body {
      background-color: $light-grey;
      padding: 15px;
      border: 1px solid $border-grey;
      .question-type { margin-top: 35px; }
      .add-answer-btn {
        display: block;
        margin: 0 auto;
      }
      .input-group {
        input[type="text"] { margin-bottom: 0; }
        .delete-answer-btn {
          border-top: 1px solid #CCC;
          border-right: 1px solid #CCC;
          border-bottom: 1px solid #CCC;
          padding: 6px 12px 7px;
        }
      }
    }
  }

  .question-notification-levels {
    max-height: 85px;
    overflow-y: auto;
    padding-left: 18px;
    list-style: none;

    li {
      display: inline-block;
      width: 50%;
      font-size: 13px;
    }
  }

  .question-tags {
    ul {
      list-style: none;
      li {
        border: 2px solid;
        border-radius: 4px;
        padding: 5px;
        background: lightgray;
      }
    }

    .remove-edit {
      cursor: pointer;
      color: red;
    }
  }

  .answer-tags {
    // margin-top: 10px;
    // border-top: 1px solid $border-grey;
    .tags {
      min-height: 100%;
      height: 100%;
      overflow: hidden;
      input[type="checkbox"] {
        position: relative;
        margin-left: auto;
      }
      ul {
        list-style: none;
      }
      .tag-boxes{
        overflow-y: scroll;
        max-height: 130px;
        padding: 10px;
        background-color: $border-grey;
      }
    }
  }
}
