#location-panel {
  a {
    cursor: pointer;
    &:focus, &:hover { text-decoration: none }
  }
  .panel-heading {
    h1 {
      margin-bottom: 15px;
    }
    button {
      margin-top: 18px;
      span {
        font-size: 18px;
        vertical-align: sub;
        margin-right: 5px;
      }
    }
  }
  .form-group{
    .search-surveys{
      width:100%;
      height:40px;
      border-radius:3px;
      border:none;
    }
    i{
      position:absolute;
      right:110px;
      top:207px;
    }
  }
  .panel-body {
    background-color: $white;
    border: 1px solid $border-grey;
    .survey-title {
      font-size:1.8rem;
    }
    .location-name {
      color: $slate;
      margin-bottom: 0;
    }
    .action-icons {
      margin-top: 7px;
      opacity: 0;
      transition: all .3s ease;
      span {
        padding: 5px;
        font-size: 17px;
        color: $teal;
        &.glyphicon-trash {
          color: $brand-danger;
        }
      }
    }
    &:hover {
      .action-icons {
        opacity: 1;
      }
    }
  }
  .location-list {
    margin-bottom: 5px;
  }
}



/*-----------------------------------------
  MEDIA QUERIES
------------------------------------------*/
@media (max-width: 768px) {
  #location-panel {
    padding: 0;
    .panel-body {
      .action-icons {
        opacity: 1;
      }
    }
  }
}


@media (max-width: 568px) {
  #location-panel {
    .panel-body {
      > .pull-left {
        width: calc(100% - 80px);
        padding-right: 0;
        .survey-title {
          font-size: 16px;
        }
      }
      .action-icons {
        span {
          font-size: 18px;
        }
      }
    }
  }
}
