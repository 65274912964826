
.question-viewer-text {
  text-align: center;
  font-size: 2rem;
  padding-bottom: 2px;
  margin-bottom: 10px;
}

.question-viewer-content {
  margin-bottom: 20px;
  margin-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid $gray-lighter;

  .question-view-item {
    margin-bottom: 20px;
  }
}

.form-question-viewer {
  background-color: $white-dark;
  .btn {
    padding: 10px;
  }
  ul {
    padding: 0;
    li {
      position: relative;
      overflow: hidden;
      list-style: none;
      margin-bottom: 0;
      label {
        margin: 0 0 10px;
        border-radius: 4px;
        overflow: auto;
        width: 100%;
        span {
          color: $gray-dark;
          display: block;
          padding: 10px 20px;
          background-color: $gray-lighter;
          border-radius: 4px;
          text-align: center;
          cursor: pointer;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
}

.modal-question-viewer {
  .modal-dialog {
    min-width: 750px;
    @media (max-width: $screen-sm-min) {
      min-width: 90%;
    }
  }
  .modal-title {
    color: $brand-primary;
    text-align: center;
  }
  .modal-content {
    border: 4px solid $brand-primary;;
    background-color: $gray-lighter;
  }
  .modal-body {
    height: 400px;
    overflow-y: auto;
    background-color: $white-dark;
  }
}

.open-instruction-modal {
  .modal-header {
    text-align: center;
  }
  .instructions {
    line-height: 1.6;
    white-space: pre-wrap;
  }
}
