/* Status Toggle */
.toggle {
    position: absolute;
    right: 10px;
    top: 17px;
    width: 55px;
    input[type="checkbox"] {
        display: none;
    }
    .status {
        position: absolute;
        top: -15px;
        width: 55px;
        color: white;
        font-size: .8em;
        text-align: center;
    }
    label:before,
    label:after {
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        content: "";
    }
    label:before {
        right: 1px;
        height: 28px;
        padding: 0 9px;
        background-color: $pink;
        border-radius: 60px;
        transition: background 0.4s;
        content:"\e014";
        font-family: 'Glyphicons Halflings';
        color: $white;
        line-height: 2;
        font-weight: normal;
        text-align: right;
    }
    input[type="checkbox"], label:after {
        width: 28px;
        height: 28px;
        background-color: #fff;
        border-radius: 100%;
        border: 4px solid $pink;
        transition: margin 0.4s;
    }
    input[type="checkbox"]:checked + label:before {
        padding-left: 7px;
        background-color: $teal;
        content: "\e013";
        text-align: left;
    }
    input[type="checkbox"]:checked + label:after {
        margin-left: 25px;
        border: 4px solid $teal;
    }
}