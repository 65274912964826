#review-survey {
  h2 {
    margin: 0;
    padding: 0 0 20px;
    border-bottom: 1px solid $light-grey;
  }
  h4, h5 {
    text-align: left;
    margin-bottom: 5px;
  }
  h4 {
    margin-top: 30px;
    margin-bottom: 20px
  }
  hr, h3 { margin-top: 5%; }
  h5 {
    color: #545454;
    margin-left: 25px;
  }
  .panel-body > h4 {
    margin: 20px 60px 20px 0;
    font-size: 18px;
  }

  .questions {
    margin: 10px 0;

    ul { list-style: none; }
    li {
      font-size: 16px;
      position:relative;
      &.responded {
        &:before {
          font-family: 'Glyphicons Halflings';
          content: "\e013";
          color: $brand-success;
          position: absolute;
          top: 1px;
          left: -25px;
        }
      }
      &.noRespond {
        color: #AAA;
        &:before {
          font-family: 'Glyphicons Halflings';
          content: "\e014";
          color: $brand-danger;
          color: #AAA;
          position: absolute;
          top: 1px;
          left: -25px;
        }
      }
    }
    .file-upload {
      max-width: 50%;
      background-color: $light-grey;
      padding: 1vw;
      display: block;
      margin: 0 auto;
      span {
        background-color: $light-grey;
        text-align: center;
        display: block;
      }
      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 100%;
        padding-bottom: 1vw;
      }
    }
    .edit {
      color: $teal;
    }
  }

}
