.form-scheduling {
    &-btn, &-input {
      background-color: $light-grey;
      color: $grey;
      margin: 0 0 20px;
      width: 100%;
      &:not(.active) {
        background-color: $light-grey;
        color: $grey;
      }
      &:active {
        background-color: $light-grey;
      }
      &:hover {
        color: $grey !important;
      }
    }

    hr {
        border-color: $light-grey;
    }

    small {
      display: block;
      margin: -15px auto 15px;
      text-align: center;
    }
  
    &-btn {
      &.active {
        background-color: $brand-primary !important;
        color: #ffffff !important;
      }
      &.btn-xsm {
        width: auto;
      }
    }
  
    input[type="date"] {
      background-color: $lighter-grey;
      border: 0;
      padding: 10px;
      &:focus {
        outline: none;
      }
    }

    .checkbox-group {
      list-style-type: none;
      label {
        position:relative;
        width: 100%;
        margin-bottom: 10px;
      }
      input[type="checkbox"] {
        position: absolute;
        top: -20px;
        display: none;
        left: -4000px;
        & + label span {
          // color: #333;
          font-weight: 100;
          display: block;
          padding: 10px 20px 10px 50px;
          background-color: transparent;
          border-radius: 4px;
          text-align: left;
          line-height: 25px;
        }
        & + label span:hover {
          color: $gray-darker;
          cursor: pointer;
        }
        & + label span:before {
          content: '';
          position: absolute;
          left: 10px;
          width: 25px;
          height: 25px;
          background-color: #eee;
          text-align: center;
        }
        &:checked + label span:before {
          content:  '\e013';
          font-family: 'Glyphicons Halflings';
          font-size: 18px;
          line-height: 1.4;
          font-style: normal;
          font-weight: 400;
          color: $teal;
        }
      }
    }
    .date-group {
      display: flex;
      align-items: center;
      label {
        width: 25%;
        font-weight: 100;
        margin-bottom: 20px;

        small {
          position: absolute;
          padding-top: 12px;
        }
      }
      input {
        flex: 1;
      }
    }
  }