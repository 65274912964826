//////////////////////
// navbar overrides //
//////////////////////

$navbar-collapse-max-height: 340px !default;
$navbar-height: 65px !default;
$navbar-padding-vertical: 15px !default;
$navbar-margin-bottom: 0 !default;
$navbar-border-width: 6px;

.navbar-brand {
  line-height: normal !important;
  span {
    margin-right: 15px;
  }
}

.navbar-nav>li>a {
  line-height: 23px !important;
  color: $grey !important;
}

.navbar-default .navbar-nav>li>a {
  border-bottom: 7px solid transparent;
  color: $grey;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
  border-color: $pink;
  color: $grey;
}


/*-----------------------------------------
  MEDIA QUERIES
------------------------------------------*/
@media (max-width: 568px) {
  .navbar-nav>li {
    float: left;
  }
  
  .navbar-nav .open .dropdown-menu {
    position: absolute;
    background-color: $white !important;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1) !important;
  }
}
