#settings {
  a { color: $teal; }
  .panel-heading {
    h1 { margin: 0; }
  }
  .tab-content {
    width: calc(75% - 25px);
    float: right;
    border: 1px solid $border-grey;
    background: $white;
    padding: 30px;
    h2 {
      margin: 0 0 20px 0;
      text-align: center;
    }
    p {
      max-width: 500px;
      width: 100%;
    }
    form {
      padding: 0;
      max-width: 500px;
      width: 100%;
      button { display: block; margin: 0 auto; }
    }
    .border {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid $border-grey;
      button {
        margin-top: 5px;
      }
    }
  }

  /* TABS -----------------------------------
  ------------------------------------------*/

  .nav-tabs {
    width: 25%;
    float: left;
    border-bottom: none;
    border-top: 1px solid $border-grey;
    border-right: 1px solid $border-grey;
    border-left: 1px solid $border-grey;
    background: $white;
    text-align: center;
    > li {
      margin-bottom: 0;
      border-bottom: 1px solid $border-grey;
      a {
        margin-right: 0;
        border: none;
        border-radius: 0;
        color: $grey;
        padding: 20px 15px;
        &:hover {
          background: $light-grey;
        }
      }
      &.active {
        a {
          background: $teal;
          color: $white;
        }
      }
      &.disabled, &.disabled a:hover {
        background-color: darken($white, 25%);
      }
    }
  }

  .nav-stacked > li + li {
    margin-top: 0;
  }
  ul {
    list-style: none;
  }
  .pencil-edit {
    cursor: pointer;
  }

  .remove-edit {
    cursor: pointer;
    color: red;
  }

  .enterprise_logo {
    text-align: center;
    height: 100%;
    img {
      height: 70px;
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
    button {
      display: block;
      margin: 25px auto;
    }
  }

}





/*-----------------------------------------
  MEDIA QUERIES
------------------------------------------*/
@media (max-width: 768px) {
  #settings {
    padding: 0;
  }
}


@media (max-width: 568px) {
  #settings {
    padding: 0;
    .nav-tabs {
      float: none;
      width: 100%;
      margin-bottom: 10px;
      > li {
        a {
          padding: 10px;
        }
      }
    }
    .tab-content {
      width: 100%;
      float: none;
      margin-left: 0;
    }
  }
}
