#import-user-modal {
  .invalid-users, .user-conflict {
    &:not(:last-child) {
      border-bottom: 1px solid $border-grey;
    }
  }
  .user-name {
    font-size: 115%;
  }

  .invalid-conflict, .local-user, .remote-user {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .duplicate-users {
    .local-col, .remote-col {
      background-color: lightgray;
    }
    .remote-col, .remote-user {
      border-left: 1px solid $border-grey;
    }
    .local-user, .remote-user {
      height: 145px;
    }
  }

  .handle-duplicates {
    margin-top: 15px;
  }

  .default-group {
    margin-bottom: 10px;
  }

  .group-selection {
    margin-top: 15px;
  }
}
