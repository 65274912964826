
.btn-question-type-upload {
  position: relative;
  overflow: hidden;
  width: 100%;

  input {
    position: absolute;
    padding: 0;
    top: 0;
    right: 0;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}
