#survey-select {
  width: 100%;
  // position: fixed;
  // top: 50%;
  // transform: translateY(-50%);

  select {
    text-align-last: center;  // Chrome only I'm afraid
  }

  .btn-start {
    margin-top: 15px;
    width: 100%;
    padding: 20px;
  }

  .form-select-survey {
    max-width: 580px;
    margin: auto;
    padding: 30px;
    background-color: $white-dark;
    input { @include pl-placeholder { text-align: center; } }
    .survey-dropdown {
      width: 100%;
      .btn-white {
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        width: inherit;
      }
      ul {
        width: inherit;
      }
      li {
        width: inherit;
        padding: 10px;
        span {
          display: none;
          &.active {
            display: inline;
            border-radius: 4px;
            color: #ffffff;
            background-color: $brand-primary;
            padding: 3px;
          }
        }
      }
      .deadline-container {
        display: inline;
        border-radius: 4px;
        padding: 3px;

        &.isDue {
          background-color: $brand-primary;
        }
        &.isDueSoon {
          background-color: orange;
        }
        &.isPastDue {
          background-color: #B7314C;
        }
        .deadline {
          color: #ffffff;
          display: inline;
        }
      }
    }
    .panel-warning {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
