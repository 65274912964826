
.list-question-tags {
  padding-left: 0px;
  li {
    list-style-type: none;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    label {
      position:relative;
      margin-bottom: 10px;
    }
    input[type="checkbox"] {
      position: absolute;
      top: -20px;
      display: none;
      left: -4000px;
      & + label span {
        color: #333;
        display: block;
        padding: 10px 20px;
        background-color: #eee;
        border-radius: 4px;
        text-align: center;
        line-height: 25px;
      }
      & + label span:hover {
        background-color: #d4d4d4;
        color: $gray-darker;
      }
      &:checked + label span {
        background-color: $teal;
        color: $white;
      }
      &:checked:hover + label span {
        background-color: $teal;
        color: $white;
      }
    }
  }
}
