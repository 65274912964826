#results-panel {
  display: flex;
  flex-direction: column;
  width: 100%;

  .panel-heading{
    margin-bottom: 15px;
    color: $white;
    font-size: 12px;
  }
  .date-bar {
    margin-top: -80px;
    margin-bottom: 20px;

    label {
      max-width: 90px;
      padding: 0 5px;
      margin: 0;
      font-weight: 300;
      font-size: 11px;
      line-height: 1;
      text-transform: uppercase;
      text-align: right;
      white-space: nowrap;
    }
    .date-tab {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      float: right;
      width: 375px;
      padding: 10px 5px;
      background-color: $border-grey;
      border-radius: 4px;

      .date-field {
        flex-grow: 1;
        display: inline-block;
        padding: 0 5px;
      }
    }
  }
  .filter-bar {
    padding: 10px 5px;
    background-color: $gray-dark;
    border-radius: 4px;
    .filter-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    .filter {
      flex-grow: 1;
      flex-basis: 0;
      padding: 0 5px;
    }
    .btn-group {
      display: block;
    }
    .btn {
      width: 100%;
      background-color: $white;
      color: $gray-darker;
      text-align: left;
      .caret {
        position: absolute;
        top: 17px;
        right: 10px;
      }
    }
  }
  .filter-added {
    display: inline-block;
    margin: 10px 2px 0;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: $dark-teal;
    color: $white;
    font-size: 12px;
    i {
      margin-left: 5px;
      margin-right: -3px;
      cursor: pointer;
    }
    &:first-of-type {
      margin-left: 5px;
    }
  }
  .form-group{
    .search-surveys{
      width: 100%;
      height: 40px;
      border-radius: 3px;
      border: none;
    }
    i{
      position:absolute;
      right: 110px;
      top: 207px;
    }
  }
  .action-buttons {
    padding: 10px 0;
  }
  .container-result {
    margin-bottom: 5px;
    background-color: $white;
    border: 1px solid $border-grey;
    .answer {
      padding: 10px;
    }
    .filter-added {
      margin: -2px -2px 0 5px;
      padding: 2px 7px;
      background-color: #fff;
      border: 1px solid #d3dadd;
      border-radius: 20px;
      font-size: .85em;
      line-height: 1.5;
      color: $gray-darker;
    }
  }
  .result {
    border-bottom: 1px solid $light-grey;
    p {
      padding: 15px 20px;
      margin-bottom: 0;
      &:nth-child(even) {
        background: $light-grey;
      }
    }
  }
  input[type="checkbox"] {

  }
  .results-header {
    margin-bottom: 10px;
    background-color: #d3dadd;
    border-radius: 4px;
    font-size: 12px;
  }
  .results-header, .result {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .result-col {
      flex-grow: 1;
      flex-basis: 0;
    }
    .row-select {
      max-width: 40px;
    }
    input[type="checkbox"] {
      margin: 0 10px;
      font-size: 20px;
    }
    a {
      margin-right: 10px;
    }
  }
  .actions {
    max-width: 100px;
    .delete {
      color: $brand-danger;
    }
  }
  .additionalDetails {
    padding: 15px 20px;
    background-color: $lighter-grey;
    ul {
      list-style: none;
      margin: 0 0 20px;
      padding: 0;
      overflow: auto;
    }
    .question {
      margin-bottom: 10px;
      padding: 5px 0;
      line-height: 1;
    }
    .answer {
      margin-left: 10px;
      padding: 7px;
      background-color: $light-grey;
      border-left: 3px solid $brand-primary;
    }
  }
}

.uib-datepicker-popup {
  .h6 {
    background-color: $light-grey;
  }
  .btn-default {
    background-color: $white;
    color: $gray-dark;
    span {
      line-height: 1.8;
    }
  }
  .btn.active {
    box-shadow: none;
    span {
      color: $brand-primary;
      font-weight: bold;
    }
  }
  .btn.btn-info.active {
    border-radius: 50%;
    background-color: $brand-primary;
    span {
      color: $white;
    }
  }
}


/*-----------------------------------------
  MEDIA QUERIES
------------------------------------------*/
@media (max-width: 768px) {
  #results-panel {
    padding: 0;
  }
}
