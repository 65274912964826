///////////////////////
// general overrides //
///////////////////////

// global link decoration
$link-hover-decoration: underline !default;

// global border radius values
$border-radius-base:  4px !default;
$border-radius-large: 6px !default;
$border-radius-small: 3px !default;

$body-padding-top: 85px !default;

// global box shadow
$box-shadow: 0 0 12px #c2cacc;
