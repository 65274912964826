.survey-panel-nav{
  margin-top: -20px;
}
#survey-panel {
  .panel-heading{
    margin-bottom: 15px;
    color: $white;
  }
  .btn-container {
    button {
      margin-left: 10px;
    }
  }
  .form-group {
    .search-surveys {
      width: 340px;
      height: 40px;
      border-top: none;
      border-left: none;
      border-right: none;
      background: none;
      border-bottom: 1px solid $border-grey;
      padding: 0 8px;
      padding-right: 30px;
      box-sizing: border-box;
      position: relative;
      color: $grey;
    }
    input:focus {
      outline: none !important;
    }
    i {
      position: absolute;
      right: 10px;
      top: 15px;
      color: $grey;
    }
  }
  .panel-body {
    background-color: $white;
    border: 1px solid $border-grey;
    padding: 0;
    .survey {
      position: relative;
      transition: all .3s ease;
      font-size: 1.8rem;
      .action-icons {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        font-size: 17px;
      }
      &:hover { background: darken($light-grey, 5%); }
      &:nth-child(even) {
        background: $light-grey;
        &:hover { background: darken($light-grey, 5%); }
      }
      &-title {
        padding: 20px;
        width: 95%;
      }
      &-dropdown-menu {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        p {
          display: inline-block;
          margin: 0;
        }
      }
      &-information {
        p {
          margin-bottom: 7px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .actions {
    margin-right: 30px;
    span {
      padding: 5px;
      font-size: 26px;
    }
  }
  .survey-list {
    margin-bottom: 10px;
  }
}

.modal-footer {
  .button-one, .button-two {
    min-width: 145px;
  }
}

.top-40 {
  margin: 40px 0 25px 0;
}

/*-----------------------------------------
  MEDIA QUERIES
------------------------------------------*/
@media (max-width: 768px) {
  #survey-panel {
    padding: 0;
  }
}
